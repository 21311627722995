// 监测方案
import request from '../request.js';
//                     方案
// 监测方案分页查询数据
export function list(data) {
    return request({
        url:'module-web/webPlanMonitor/list',
        method:'post',
        data
    })
}
// 方案状态启用
export function updateStartupById(params) {
    return request({
        url:`module-web/webPlanMonitor/updateStartupById`,
        method:'get',
        params
    })
}
// 方案状态停用
export function updateShutdownById(params) {
    return request({
        url:`module-web/webPlanMonitor/updateShutdownById`,
        method:'get',
        params
    })
}
// 删除方案
export function deletePlan(id) {
    return request({
        url:`module-web/webPlanMonitor/delete/${id}`,
        method:'get'
    })
}
// 保存方案
export function savePlanMonitor(data) {
    return request({
        url:'module-web/webPlanMonitor/savePlanMonitor',
        method:'post',
        data
    })
}
// 方案详情回显
export function selectPlanMonitor(id) {
    return request({
        url:`module-web/webPlanMonitor/selectPlanMonitor/${id}`,
        method:'get'
    })
}
// 编辑接口
export function updatePlanMonitor(data){
    return request({
        url:'module-web/webPlanMonitor/updatePlanMonitor',
        method:'post',
        data
    })
}

//            数据
// 获取数据列表
export function planMonitorDataList(data) {
    return request({
        url:"module-polyse/planMonitorData/planMonitorDataList",
        method:'post',
        data
    })
}
// 收藏
export function collect(id) {
    return request({
        url:`module-polyse/planMonitorData/collect/${id}`,
        method:'get'
    })
}
// 取消收藏
// /module-polyse/planMonitorData/cancelCollect/{id}
export function cancelCollect(id) {
    return request({
        url:`module-polyse/planMonitorData/cancelCollect/${id}`,
        method:'get'
    })
}
// 删除
export function deleteData(data) {
    return request({
        url:"module-polyse/planMonitorData/delete",
        method:'post',
        data
    })
}
// /module-polyse/planMonitorData/read
// 批量已读
export function read(data) {
    return request({
        url:'module-polyse/planMonitorData/read',
        method:'post',
        data
    })
}
// 详情
// /module-polyse/planMonitorData/info/{id}
export function info(id) {
    return request({
        url:`module-polyse/planMonitorData/info/${id}`,
        method:'get'
    })
}
// 导出 /
export function exportExcel(data) {
    return request({
        url:"module-polyse/planMonitorData/exportExcel",
        method :"post",
        data,
        responseType: "blob",
    })
}