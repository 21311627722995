<template>
  <div class="deleteDialog">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="27%" :close-on-click-modal="false" :close-on-press-escape="false"
               :modal-append-to-body="false">
      <div class="deleteDivTwo">
        <el-row class="m-b-16 flex-c">
          <el-col :span="4" :offset="0">旧密码</el-col>
          <el-col :span="18" :offset="0">
            <el-input v-model="oldPassWord" placeholder="" size="normal" clearable></el-input>
          </el-col>
        </el-row>
        <el-row class="m-b-16 flex-c">
          <el-col :span="4" :offset="0">新密码</el-col>
          <el-col :span="18" :offset="0">
            <el-input v-model="newPassWord" placeholder="" size="normal" clearable></el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureClick">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      dialogVisible: false,
      oldPassWord: '',
      newPassWord: ''
    };
  },
  props: {},
  methods: {
    sureClick() {
      this.$emit("updatePassWord", {
        password: this.oldPassWord,
        newPassword: this.newPassWord
      });
    },
  },
};
</script>

<style lang="less" scoped>

</style>