<template>
  <div class="layout" style="min-width: 1625px">
    <div :class="isCollapse ? 'layout-aside-two' : 'layout-aside'" v-if="
        $route.path != '/bigdata/harmfulInformation' &&
          $route.path != '/bigdata/misrepresentation' &&
          $route.path != '/bigdata/exceptionFilter'
      ">
      <Aside :isCollapse="isCollapse" />
    </div>
    <div :class="[
        isCollapse ? 'layout-header-two' : 'layout-header',
        $store.state.openNewUrl &&
        ($route.path == '/bigdata/harmfulInformation' ||
          $route.path == '/bigdata/misrepresentation' ||
          $route.path == '/bigdata/exceptionFilter')
          ? 'openNewUrlStyle'
          : '',
      ]" style="min-width: 600px">
      <Header ref="header" @change="change" />
    </div>
    <div :class="[
        isCollapse ? 'layout-main-two' : 'layout-main',
        $store.state.openNewUrl &&
        ($route.path == '/bigdata/harmfulInformation' ||
          $route.path == '/bigdata/misrepresentation' ||
          $route.path == '/bigdata/exceptionFilter')
          ? 'openNewUrlStyle'
          : '',
      ]">
      <router-view />
    </div>

    <el-dialog title="提示" :visible.sync="loginTipsShow" width="40%" :before-close="loginTipsClose">
      <div id="zb_layout_tips_main">
        <el-image :src="loginTips.url" v-show="loginTips.url"></el-image>
        <h2 v-html="loginTips.title"></h2>
        <p v-html="loginTips.text"></p>
      </div>
      <span slot="footer" class="zb_layout_tips dialog-footer">
        <el-button type="primary" size="small" @click="loginTipsClose">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出进度条 -->
    <ExportDialog ref="exportDialog"></ExportDialog>
  </div>
</template>

<script>
import Header from "../components/Header";
import Aside from "../components/Aside";
import ExportDialog from "@/components/exportDialog.vue";
export default {
  components: {
    Header,
    Aside,
    ExportDialog,
  },
  data() {
    return {
      isCollapse: this.$store.state.isCollapse,
      //机构类型提示弹窗
      loginTipsShow: false,
      loginTips: {
        url: "../assets/img/index/loginTrial.png",
        title: "",
        text: "",
        time: -1,
      },
      data: {},
    };
  },
  methods: {

    // 关闭到期弹窗
    loginTipsClose() {
      // 如果是已过期 那就回到登陆页去
      if (this.data.prodType == -1) this.$router.push('/login')
      this.loginTipsShow = false;
      this.daoqitishi(this.data);
    },
    // 即将到期的提示
    daoqitishi(data) {
      if (!data.willExpire) return;
      this.$message({
        type: "error",
        dangerouslyUseHTMLString: true,
        iconClass: "el-icon-info",
        customClass: "zb_layout_stop",
        message: `<h3 style="margin-left:10px">即将到期</h3><br/><p style="margin-left:10px">您的<b>试用版本</b>即将于${data.expireDate}到期，为避免数据丢失请注意及时处理！</p>`,
      });
    },
    // 获取当前机构的详情
    async baseInfo() {
      const data = JSON.parse(window.localStorage.getItem("prod"));
      if (!data) {
        await this.$main.mySetTimeOut(200);
        this.baseInfo();
        return;
      }
      this.$refs.header.getInfo(data);
      this.data = data;
      // 试用版本
      if (data.prodType == 0) {
        this.loginTipsShow = !JSON.parse(window.localStorage.getItem('isWangxinban'));
        this.loginTips.url = require("../assets/img/gjh/sydq.png");
        this.loginTips.title =
          "尊敬的用户：您当前使用的为<span style='color:#DD3B4A'>试用版</span>服务";
        this.loginTips.text = `您的试用于${data.expireDate}到期，<br/>
        届时我们将删除您的舆情监测以及所有相关数据和服务。<br/>
        若要享受我们的完整服务，请联系您的顾问升级到<span style="color:#409EFF">专业版</span>。<br/>
        <br/>
        您的体验对我们非常重要，请分享您的反馈，<br/>
        我们会借此改进我们的服务，并在今后更好地满足用户需求。`;
      }
      //  已到期
      if (data.prodType == -1) {
        this.loginTipsShow = !JSON.parse(window.localStorage.getItem('isWangxinban'));
        this.loginTips.url = require("../assets/img/gjh/gq.png");
        this.loginTips.title = "您无法继续使用，因为您的服务已到期";
        this.loginTips.text = `使用期已过期，若要继续使用，您需要联系您的顾问续订我们的服务。<br/>
        您的监测信息将存储1年。若要详细了解可用信息，请咨询您的商务顾问。<br/>
        <br/>
        您的体验对我们非常重要，请分享您的反馈，<br/>
        我们会借此改进我们的服务，并在今后更好地满足用户需求。`;

      }
      // 正式版
      if (data.prodType == 1) this.daoqitishi(data);
    },
    change() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit("sedData", this.isCollapse);
    },
    // 时间转换方法(默认GMT时间格式)
    formatDate(d) {
      var date = new Date(d);
      var YY = date.getFullYear() + "-";
      var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    // 时间转换方法(CST时间格式)
    dateCST(sDate) {
      var dateS = sDate.split(" ");
      var strGMT =
        dateS[0] + " " + dateS[1] + " " + dateS[2] + " " + dateS[5] + " " + dateS[3] + " GMT+0800";
      var date = new Date(Date.parse(strGMT));
      date = this.formatDate(date);
      return date;
    },
    // 获取当前时间
    getLocalTime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let hours = date.getHours();
      hours = hours >= 10 ? hours : "0" + hours;
      let minutes = date.getMinutes();
      minutes = minutes >= 10 ? minutes : "0" + minutes;
      let seconds = date.getSeconds();
      seconds = seconds >= 10 ? seconds : "0" + seconds;
      return y + "-" + m + "-" + d + " " + hours + ":" + minutes + ":" + seconds;
    },
    // 公共下载方法(数据流形式)
    downloadPublicFun(obj, type = '') {
      if (type) {
        this.$http
          .post(obj.url, obj.data, {
            responseType: "blob",
          }).then(() => {
            this.$message.success('正在加载数据,请稍后在下载中心查看')
          })
        return
      }
      if (obj.url !== undefined && obj.data !== undefined && obj.textName !== undefined) {
        this.$refs.exportDialog.exportVisible = true;
        this.$refs.exportDialog.downloadProgress();
        this.$http
          .post(obj.url, obj.data, {
            responseType: "blob",
          })
          .then((res) => {
            let blob = new Blob([res.data], {
              type:
                "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob);
            } else {
              let elink = document.createElement("a");
              elink.download = obj.textName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              document.body.removeChild(elink);
            }
            clearTimeout(this.$refs.exportDialog.timer);
            this.$refs.exportDialog.wite = false;
            this.$refs.exportDialog.percentage = 100;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
      if (!obj) {
        this.$refs.exportDialog.exportVisible = true;
        this.$refs.exportDialog.percentage = 100;
      }
    },
  },
  created() { },
  mounted() {

    const baseInfoInx = window.localStorage.getItem("baseInfoInx"); // 判断是第几次
    if (!baseInfoInx) {
      this.$nextTick(() => {
        this.baseInfo();
      })
      window.localStorage.setItem("baseInfoInx", 1); // 判断是第几次
    }
  },
};
</script>

<style scoped>
.openNewUrlStyle {
  width: 100% !important;
  margin-left: 0px !important;
}
.layout {
  position: relative;
  height: 100%;
  width: 100%;
}

.layout-header {
  width: calc(100% - 228px);
  height: 80px;
  position: fixed;
  margin-left: 228px;
  z-index: 22;
  -webkit-transition: all 0.28s;
  transition: all 0.28s;
  /* background: linear-gradient(180deg, #267DFF 0%, #267DFF 100%); */
}
.layout-header-two {
  width: calc(100% - 96px);
  height: 80px;
  position: fixed;
  margin-left: 96px;
  z-index: 22;
  -webkit-transition: all 0.28s;
  transition: all 0.28s;
}
.layout-aside {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 228px;
  transition: width 0.28s;
  overflow: hidden;
  /* background: #FFD5B4; */
  /* background: url(../assets/img/aside/bg.png) no-repeat center; */
  z-index: 22;
}
.layout-aside-two {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 96px;
  transition: width 0.28s;
  overflow: hidden;
  background: linear-gradient(180deg, #267dff 0%, #267dff 100%);
  z-index: 22;
}
.layout-main {
  min-height: 100%;
  padding-top: 80px;
  -webkit-transition: margin-left 0.28s;
  transition: margin-left 0.28s;
  margin-left: 228px;
  position: relative;
  background: #f4f5fa;
}
.layout-main-two {
  min-height: 100%;
  padding-top: 80px;
  -webkit-transition: margin-left 0.28s;
  transition: margin-left 0.28s;
  margin-left: 96px;
  position: relative;
  background: #f4f5fa;
}
.zb_layout_tips {
  display: block;
  width: 100%;
  text-align: center;
}
#zb_layout_tips_main {
  text-align: center;
}
#zb_layout_tips_main h2 {
  margin: 20px 0;
}
#zb_layout_tips_main p {
  line-height: 30px;
}
</style>
