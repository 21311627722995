<template>
  <div class="aside-container">
    <div id="holder"></div>
    <div class="logo">
      <img v-if="isCollapse" class="img-one" :src="logoSmall" alt="" />
      <img v-else class="img-two" :src="logoBig" alt="" />
    </div>
    <div class="navigation" :style="isCollapse ? 'height:70%' : ''">
      <!-- 展开 -->
      <div v-show="!isCollapse" :class="item.isSelect ? 'menu2' : 'menu'" v-for="item in list" :key="item.path" :style="style(item)" @click="menuSelect(item)">
        <img v-if="item.isSelect" :src="item.icon1" alt="" />
        <img v-else :src="item.icon2" alt="" />
        <span>{{ item.name }}</span>
      </div>
      <!-- 收起 -->
      <div v-show="isCollapse" class="collect" :style="
          item.isSelect
            ? 'background: linear-gradient(270deg, #F89D55 0%, #F4630B 100%)'
            : ''
        " v-for="(item, index) in list" :key="index" @click="menuSelect(item)">
        <el-tooltip effect="dark" :content="item.name" placement="right">
          <img v-if="item.isSelect" :src="item.icon1" alt="" />
          <img v-else :src="item.icon2" alt="" />
        </el-tooltip>
      </div>
    </div>
    <div v-if="technicalSupport == 1" :class="isCollapse ? 'technology-two' : 'technology'">
      <span>技术支持:康奈网络 ©</span>
      <span>copyright 2022</span>
    </div>
  </div>
</template>

<script>
import { getMatMenuList } from "@/api/login/index.js";
import { list } from "../api/monitor";
import { Dictionaries } from "../api/platformaccount";
export default {
  props: {
    isCollapse: {
      //菜单的左右收起
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Waves: {},
      list: [],
      technicalSupport: 0, //是否技术支持
      logoBig: require("../assets/img/aside/logo.png"), //大log
      // logoBig: require("../assets/img/aside/logo2.png"), //陕西省委的大log
      logoSmall: require("../assets/img/aside/logo-min.png"), //小logo
    };
  },
  created() {
    // 给陕西省委 打包用的图片
    if (JSON.parse(window.localStorage.getItem('isWangxinban'))) {
      this.logoBig = require("../assets/img/aside/logo2.png") //陕西省委的大log
    }
    this.getMatMenuList();
  },
  watch: {
    $route(to) {
      this.getDictionariesList();
      this.RouteFollow(to);
    },
  },
  mounted() {
    this.RouteFollow(this.$route);
  },
  methods: {
    // 请求字典
    getDictionariesList() {
      const todata = (data) =>
        data.map((item) => ({ label: item.label, value: item.value }));

      const ifgetItem = (name) => {
        const list = localStorage.getItem(name) || []
        return !list || list.length == 0
      };
      // 平台类型
      // if (ifgetItem("platform_type")) {
      Dictionaries("platform_type").then((Res) => {
        window.localStorage.setItem(
          "platform_type",
          JSON.stringify(todata(Res.data.data || []))
        );
      });
      // }

      // 主体类型
      // if (ifgetItem("body_type")) {
      Dictionaries("body_type").then((Res) => {
        window.localStorage.setItem(
          "body_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // }
      // 信息类型
      // if(ifgetItem("message_type")) {
      Dictionaries("message_type").then((Res) => {
        window.localStorage.setItem(
          "message_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // }
      // 认证情况
      //       if(ifgetItem("is_authenticate")) {
      Dictionaries("is_authenticate").then((Res) => {
        window.localStorage.setItem(
          "is_authenticate",
          JSON.stringify(todata(Res.data.data))
        );
      });
      //       }

      // 语言种类
      // if(ifgetItem('language_category')) {
      //   Dictionaries("language_category").then((Res) => {
      //   window.localStorage.setItem(
      //     "language_category",
      //     JSON.stringify(todata(Res.data.data))
      //   );
      // });
      // } 

      // 主办单位性质
      //   if(ifgetItem('organizer_type')) {
      //   Dictionaries("organizer_type").then((Res) => {
      //     window.localStorage.setItem(
      //       "organizer_type",
      //       JSON.stringify(todata(Res.data.data))
      //     );
      //   });
      // }
      // 前置审批
      // if(ifgetItem('pre_approval')) {
      //   Dictionaries("pre_approval").then((Res) => {
      //   window.localStorage.setItem(
      //     "pre_approval",
      //     JSON.stringify(todata(Res.data.data))
      //   );
      // });
      // }

      // 国家
      // if(ifgetItem('access_country')) {
      //   Dictionaries("access_country").then((Res) => {
      //   window.localStorage.setItem(
      //     "access_country",
      //     JSON.stringify(todata(Res.data.data))
      //   );
      // });
      // }

      // 境内外接入
      // if(ifgetItem('access_way')) {
      //   Dictionaries("access_way").then((Res) => {
      //   window.localStorage.setItem(
      //     "access_way",
      //     JSON.stringify(todata(Res.data.data))
      //   );
      // });
      // }

      // 微信公众号-处置状态
      // if(ifgetItem('wechat_dispose_state')) {
      //   Dictionaries("wechat_dispose_state").then((Res) => {
      //   window.localStorage.setItem(
      //     "wechat_dispose_state",
      //     JSON.stringify(todata(Res.data.data))
      //   );
      // });
      // }

    },
    // 路由跟随 自动选择菜单
    RouteFollow(to) {
      this.list.forEach((e) => {
        e.isSelect = false;
        if (to.meta.activePath == e.path) e.isSelect = true;
      });

    },
    // 获取菜单
    async getMatMenuList() {
      const data = JSON.parse(window.localStorage.getItem("menu"));
      this.list = data.map((v) => ({
        name: v.name,
        isSelect: false,
        path: v.path,
        icon1: v.icon.split(",")[0],
        icon2: v.icon.split(",")[1],
      }));
      //属地省委功能模块---------------------------------------------------------------
      if (JSON.parse(window.localStorage.getItem("isWangxinban"))) {
        this.list.push(
          {
            name: '人员管理',
            isSelect: false,
            path: '/account',
            icon1: require('../assets/img/zhang/yonghuguanli1.png'),
            icon2: require('../assets/img/zhang/yonghuguanli.png'),
          },
          {
            name: '可视化大屏',
            isSelect: false,
            path: '/bigPing',
            icon1: require('../assets/img/zhang/daping1.png'),
            icon2: require('../assets/img/zhang/daping.png'),
          }
        )
      }
      //属地更新迭代功能模块-------------------------------------------------------------
      if (JSON.parse(window.localStorage.getItem("is_show_update"))) {
        this.list.push(
          // {
          //   name: '新的排查功能',
          //   isSelect: false,
          //   path: '/newCheck',
          //   icon1: require('../assets/img/zhang/paicha1.png'),
          //   icon2: require('../assets/img/zhang/paicha.png'),
          // },
          // {
          //   name: '排查功能',
          //   isSelect: false,
          //   path: '/troubleshoot',
          //   icon1: require('../assets/img/zhang/paicha1.png'),
          //   icon2: require('../assets/img/zhang/paicha.png'),
          // },
          // {
          //   name: '处置结果统计',
          //   path: '/disposeCount',
          //   icon1: require('../assets/img/zhang/shujutongji1.png'),
          //   icon2: require('../assets/img/zhang/shujutongji.png'),
          // }
        )
      }
      // this.list.push(
      //   {
      //     name: '属地大屏',
      //     isSelect: false,
      //     path: '/daping',
      //     icon1: require('../assets/img/zhang/daping1.png'),
      //     icon2: require('../assets/img/zhang/daping.png'),
      //   }
      // )
      this.list.forEach((e) => {
        if (window.localStorage.getItem("path") == e.path) {
          e.isSelect = true;
        }
      });
      // }
    },
    style(item) {
      let style = "";
      if (item.isSelect) {
        style =
          "background: linear-gradient(270deg, #F89D55 0%, #F4630B 100%);border-radius:10px;color:#ffffff";
      }
      return style;
    },
    menuSelect(item) {
      //菜单选择
      this.list.forEach((e) => {
        //取消所有选中，重新选中
        e.isSelect = false;
      });
      if (item.path == "/bigdata" || item.path == "/daping") {
        item.isSelect = false;
        this.list.forEach((x) => {
          if (localStorage.getItem("path") == x.path) {
            x.isSelect = true;
          }
        });
      } else {
        item.isSelect = true;
      }
      // window.localStorage.setItem('button',item.authButtons) //缓存按钮权限
      // window.localStorage.setItem("Refresh", true); //刷新
      if (item.path == "/bigdata" || item.path == "/daping") {
        this.$store.commit("setOpenNewUrl");
        let routeUrl = this.$router.resolve({
          path: item.path,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.$store.commit("setOpenNewUrlAgin");
        this.$router.push({ path: item.path });
        window.localStorage.setItem("path", item.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 例如给div添加样式 */
div {
  -webkit-user-select: none; /*谷歌 /Chrome*/
  -moz-user-select: none; /*火狐/Firefox*/
  -ms-user-select: none; /*IE 10+*/
  user-select: none;
}

.aside-container {
  height: 100%;
  // overflow-y: scroll;
  // overflow-x: hidden;
  box-sizing: inherit;
  // margin-right: -17px;
  // margin-bottom: -17px;
  position: relative;
  background-size: 300px 1080px !important;
  // background: url(../assets/img/aside/bg.png) no-repeat center;
  #holder {
    position: absolute;
    background: url(../assets/img/aside/bg.png) no-repeat center;
    background-size: 123% 105%;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  .navigation {
    height: 80.04%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .navigation::-webkit-scrollbar {
    width: 0;
  }
  .technology {
    display: flex;
    // align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-top: 17px;
    font-size: 12px;
    color: #ffffff;
    span {
      text-align: center;
    }
  }
  .technology-two {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 17px;
    font-size: 12px;
    color: #ffffff;
    span {
      writing-mode: vertical-lr;
    }
    span:nth-child(1) {
      margin-right: 10px;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    cursor: pointer;
    width: 190px;
    height: 48px;
    margin: 0px 24px 16px 24px;
    img {
      margin-left: 18px;
      width: 32px;
      height: 32px;
    }
    span {
      margin-left: 18px;
      white-space: nowrap;
      font-size: 16px;
    }
  }
  .menu2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #0d3055;
    cursor: pointer;
    width: 190px;
    height: 48px;
    border-radius: 10px;
    //  background: linear-gradient(270deg, #F89D55 0%, #F4630B 100%);;
    margin: 0px 24px 16px 24px;
    img {
      margin-left: 18px;
      width: 32px;
      height: 32px;
    }
    span {
      margin-left: 18px;
      white-space: nowrap;
      font-size: 16px;
    }
  }
  .menu:hover {
    color: #d8d5d5;
  }
  .collect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    margin: 0px 24px 12px 24px;
    border-radius: 10px;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .second {
    display: flex;
    align-items: center;
    padding-left: 65px;
    width: 180px;
    height: 48px;
    margin: 0px 24px;
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    transition: height 0.28s;
  }
  .second:hover {
    color: #d8d5d5;
  }
  .second2 {
    display: flex;
    align-items: center;
    padding-left: 65px;
    width: 180px;
    height: 0px;
    margin: 0px 24px;
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    transition: height 0.28s;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 26px 0px 62px 0px;
    z-index: 10;
    .img-one {
      height: 30px;
      width: 47px;
    }
    .img-two {
      height: 30px;
      width: 130px;
    }
  }
}
.aside-container::-webkit-scrollbar {
  width: 0px;
}
</style>
