//平台台账
import request from '../request.js';
// 新增 || 保存
export function addaccount(data) { 
    return request({
        url:"module-account/account",
        method:'post',
        data
    })
}
// 编辑
export function update(data) {
    return request({
        url:'module-account/account',
        method:'put', 
        data
    })
}
// 更新标签
export function account(data) {
    return request({
        url:'module-account/account',
        method:'put', 
        data
    })
}
//  编辑的时候调用工商信息
export function upbusinessInfo(data) {
    return request({
        url:'module-web/businessInfo/saveOrUpdate',
        method:'post', 
        data
    })
}
// 删除
export function deleteaccount(params) {
    return request({
        url: "module-account/account/deleteFromFront",
        method: 'delete',
        params
    })
}
//分页查询 && 列表
export function queryPage(data) {
    return request({
        url: "module-account/account/queryPage",
        method: 'post',
        data
    })
}
// 查询详情
export function detail(params) {
    return request({
        url:"module-account/account/detail",
        method:'get',
        params
    })
}
// 分页查询相似账号
export function querySimilarPage(params) {
    return request({
        url:"module-account/account/querySimilarPage",
        method:"get",
        params
    })
}
// 查标签
export function getList(data) {
    return request({
        url:"module-system/orgLabel/list",
        method:"post",
        data
    })
}

// 字典表
export function Dictionaries(params) {
    return request({
        url:`module-system/sysDictData/info/${params}`,
        method:"get"
    })
} 
// 导出
export function exportExcel(data) {
    return request({
        url:`module-account/account/exportExcel`,
        method:"post",
        data,
        responseType: "blob",
    })
} 
// 工商信息
export function businessInfo(socialCreditCode) {
    return request({
        url:`module-web/businessInfo/${socialCreditCode}`,
        method:"get"
    })
}
//  导入模板
export function downloadStandingTemplate() {
    return request({ 
        url:"module-account/accountImportBatch/downloadStandingTemplate",
        method:'get',
        responseType: "blob",
    })
} 
//矩阵账号导入批次查询
export  function queryById(data) {
    return request({
        url: "module-account/accountImportBatch/queryById/"+data.batchId,
        method: 'put',
    })
}
//分页查询预览数据
export function list(data) {
    return request({
        url:"module-account/accountImportBatch/list",
        method:'post',
        data
    })
}
// 同步数据
export function synchInfo(batchId) {
    return request({
        url:`module-account/accountImportBatch/synchInfo/${batchId}`,
        method :'put'
    })
}