import request from "../request.js";
// 获取列表
function getList(data) {
  return request({
    url: "/module-system/exportRecord/list",
    method: "post",
    data,
  });
}
// 批量删除
function delList(data) {
  return request({
    url: "/module-system/exportRecord/deleteBatch",
    method: "post",
    data,
  });
}
//获取下载中心的 生成中任务数量
export function getDownloadCount() {
  return request({
    url: "/module-system/exportRecord/downloadCount",
    method: 'get'
  })
}
export default {
  getList, delList, getDownloadCount
}