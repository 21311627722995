import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
//element-ui  组件库引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入scroll 
import scroll from "vue-seamless-scroll";
//自适应插件js
import '@/utils/rem'
import * as echarts from 'echarts';
import Vant from 'vant'
import 'vant/lib/index.css';
Vue.use(Vant)

Vue.use(ElementUI);
Vue.use(scroll)
// axios 请求引入
import service from './api/request';
Vue.prototype.$http = service;
Vue.prototype.$axios = axios
//引入 状态管理 vuex 配置文件
import store from './store/index';
// 路由router引入
import router from './router';
//引入公共JS
import main from './utils/main';
Vue.prototype.$echarts = echarts
Vue.prototype.$main = main;
Vue.config.productionTip = false;
// 引入重置样式文件
import "./assets/css/public.css";
import "./assets/css/index.less";

// // 引入重置样式文件
// import "./assets/css/public.css";
// // 引入媒体查询
// import "./assets/css/mediaCss.css";
// // 引入业务css
import './assets/css/common.css'
//全局注入 可以$前缀使用  this.$router




new Vue({
    el: '#app',
    router,
    store,
    main,
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = new Vue()//安装全局事件总线
    }
});