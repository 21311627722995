var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"back-ground"}),_c('div',{staticClass:"content"},[(
        _vm.$route.path != '/bigdata/harmfulInformation' &&
          _vm.$route.path != '/bigdata/misrepresentation' &&
          _vm.$route.path != '/bigdata/exceptionFilter' &&
          !_vm.$store.state.isCollapse
      )?_c('img',{staticStyle:{"margin-left":"30px","margin-right":"30px"},attrs:{"src":require("@/assets/img/header/fold.png"),"alt":""},on:{"click":_vm.change}}):_vm._e(),(
        _vm.$route.path != '/bigdata/harmfulInformation' &&
          _vm.$route.path != '/bigdata/misrepresentation' &&
          _vm.$route.path != '/bigdata/exceptionFilter' &&
          _vm.$store.state.isCollapse
      )?_c('img',{staticStyle:{"margin-left":"30px","margin-right":"30px"},attrs:{"src":require("@/assets/img/header/unfold.png"),"alt":""},on:{"click":_vm.change}}):_vm._e(),_c('div',{class:_vm.$route.path != '/bigdata/harmfulInformation' &&
        _vm.$route.path != '/bigdata/misrepresentation' &&
        _vm.$route.path != '/bigdata/exceptionFilter'
          ? 'box'
          : 'boxTwo'},[_c('div',{staticClass:"box-title"},[_vm._v(_vm._s(_vm.systemName))]),_c('div',{staticClass:"box-desc"},[_vm._v(_vm._s(_vm.myinfo.orgName))])]),_c('div',{staticClass:"boxRight"},[_c('div',{on:{"click":_vm.goDownload}},[_c('el-badge',{staticClass:"download",attrs:{"value":_vm.downloadCount,"max":99,"hidden":_vm.downloadCount==0}},[_c('img',{attrs:{"src":require("../assets/img/header/xiazai.png"),"alt":""}})])],1),(!_vm.isWangxinban)?[(_vm.prod.prodType == -1)?_c('img',{staticClass:"img",attrs:{"src":require("../assets/img/gjh/ty.png"),"alt":"","srcset":""}}):_vm._e(),(_vm.prod.prodType == 0)?_c('img',{staticClass:"img",attrs:{"src":require("../assets/img/gjh/sy.png")}}):_vm._e(),(_vm.prod.prodType == 1)?_c('img',{staticClass:"img",attrs:{"src":require("../assets/img/gjh/zs.png")}}):_vm._e()]:_vm._e(),_c('el-dropdown',[_c('div',{staticClass:"boxRight-center m-l-24 m-r-30"},[_c('div',[_vm._v(_vm._s(_vm.myinfo.name)+"，欢迎登录 ！")]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.dataTime))])])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.myinfo.roleId!==1),expression:"myinfo.roleId!==1"}],nativeOn:{"click":function($event){return _vm.passWordOpen.apply(null, arguments)}}},[_vm._v("修改密码")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_vm._v("退出登录")])],1)],1)],2),_c('DeleteDialog',{ref:"openDialog",on:{"deleteFn":_vm.dataDelete}}),_c('PassDialog',{ref:"passDialog",on:{"updatePassWord":_vm.updatePassWord}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }