// 因为已经全局注册了  大家在页面里用的时候 直接 this.$main.方法名  使用就行  无需再引入

// scroll 滚动插件的公共配置
import classOption from './scroll'
// g 全局常用的公共函数  gjh写的 大家可写在这里边  也可以自己定义
import * as g from './g'
import fecha from './date';
export default {
    isDate(date) {
        if (date === null || date === undefined) return false;
        if (isNaN(new Date(date).getTime())) return false;
        return true;
    },
    toDate(date) {
        return this.isDate(date) ? new Date(date) : null;
    },
    // 时间转换
    timeCycle(date, format) {
        /* 
            格式	含义	备注	举例
            yyyy	年		        2017
            M	    月	    不补0	1
            MM	    月		        01
            d	    日	    不补0	2
            dd	    日		        02
            H	    小时	不补0	3
            HH	    小时	    	03
            m	    分钟	不补0	4
            mm	    分钟		    04
            s	    秒	    不补0	5
            ss	    秒		        05 
        */
        date = this.toDate(date);
        if (!date) return '';
        return fecha.format(date, format || 'default');
    },
    classOption,
    ...g
}