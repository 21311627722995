import axios from "axios";
import ElementUI from "element-ui";
import router from "../router";
/* import ElementUI from "element-ui";
import router from "../router";
import store from '../store/index'; 
// 接口
axios({ 
    url: "url",
    method: 'post', 
    data: {}
});
// 跳转
router.push({
    path: "/login", 
    errorMsg: '8' 
}); 
//提示
ElementUI.Message({
    message: response.data.msg,
    type: "warning",
}); */
import { peizhi } from "./peizhi";
const service = axios.create({
    // baseURL: "http://api.admin.konne.cn/"//测试
    // baseURL: "https://api.juzhen.yuqing.cn"// 发 布   
    // baseURL: 'http://10.5.4.19:65003', // 网信办 
    // baseURL: 'http://172.16.2.91:8888', // 网信办  给省委打包发这个
    baseURL:peizhi().loginBaseURL
});
service.interceptors.request.use(
    config => {
        // 一般在这个位置判断token是否存在
        // Bearer 4wE57n9XyRSPEnXQt3UCf7lB4LpI6rO8   //梁国东的
        const token = 'Bearer ' + window.localStorage.getItem('token')
        // const token = sessionStorage.getItem("token") || "Bearer B7DIQ5BdNQ3uP0gVkHC1IvW30uOyxCtD";
        if (token) {
            config.headers.Authorization = token;

        }
        return config;
    },
    error => { 
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

//响应拦截!
service.interceptors.response.use( 
    response => {
        // 处理响应数据
        if (response.data.code === 200) {
            return Promise.resolve(response)
        }else if (response.data.code === 400) {
            ElementUI.Message({
              message: response.data.msg,  
              type: "warning",
            });
            router.push({
                path: "/login",
              });
          }   
        else {
            return Promise.resolve(response)
        }
    },
    // 处理响应失败
    error => Promise.reject(error)

);
export default service;