import request2 from '../loginMenu.js';
import request from '../request';
export function getMatMenuList(data) {
    return request2(
        {
            url: 'sdbackend/apanageMenu/front/getMatMenuList',
            method: 'post',
            data
        }
    )
}
//查询地区
export function getAreaTree() {
    return request({
        url: 'module-auth/areaInfo/getAreaTree',
        method: 'get'
    })
}

// 查询省份
export function provinceList(data) {
    return request2({
        url: 'system/sysRegion/provinceList',
        method: 'post',
        data
    })
}
// 查询城市根据省份code
export function cityList(data) {
    return request2({
        url: 'system/sysRegion/cityList',
        method: 'post',
        data
    })
}
// 查询区
export function districtList(data) {
    return request2({
        url: 'system/sysRegion/districtList',
        method: 'post',
        data
    })
}

//  登录有关
//获取验证码
export function getLoginJzMsg(data) {
    return request2({
        url: "sdbackend/cancelCheck/getLoginDpMsg",
        method: 'post',
        data
    })
}
//矩阵-前台-登录
// export  function cusBackPcLogin(data) {
//     return request2({
//         url:"mtBackend/cancelCheck/front/cusBackPcLogin",
//         method: 'post',
//         data
//     })
// }
//登录-登录背景
export function loginBackground(data) {
    return request2({
        url: "sdbackend/cancelCheck/loginBackground" + `?requestDomain=${data.requestDomain}`,
        method: 'get',
    })
}
//账号密码登陆
// export function cusBackPcLoginPassw(data) {
//     return request2({
//         url:"mtBackend/cancelCheck/front/cusBackPcLoginPassw",
//         method: 'post',
//         data
//     })
// }
//登出
export function logout(data) {
    return request2({
        url: "sdbackend/cancelCheck/logout",
        method: 'get',
        data
    })
}
// 修改密码
export function updatePassWord(data) {
    return request2({
        url: "sdbackend/cancelCheck/updatePassWord",
        method: "post",
        data,
    });
}
//扫码登陆
export function wxLogin(data) {
    return request2({
        url: "mtBackend/cancelCheck/front/cusPcLoginByScanCode",
        method: 'post',
        data
    })
}
//组合登录
export function combinationLogin(data) {
    return request2({
        url: "sdbackend/cancelCheck/front/login",
        method: 'post',
        data
    })
}
//登录人机构详情
// export function orgInfo(data) {
//     return request2({
//         url:"mtBackend/matrixBackendOrg/orgInfo",
//         method: 'get',
//         data
//     })
// }