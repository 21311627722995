//  g 全局
import { Message } from "element-ui";
import router from "../router/index";
//根据文字的长度来获取宽度
export const fontWidth = (font) => font.length * 14 + "px";
// 封装settimeout  配合async await 或者 then 来食用
// setTimeout:(time) => new Promise(res =>  setTimeout(res,time)),
export const mySetTimeOut = (time) =>
  new Promise((res) => setTimeout(res, time));
// 复制
export const copyFun = (str, title) => {
  let reg = new RegExp("null", "g"); //g代表全部
  if (str) {
    str = str.replace(reg, "无");
  }
  let htmlGPs = new RegExp("<br/>", "g");
  if (str) {
    str = str.replace(htmlGPs, ",");
  }
  let htmlGPss = new RegExp("<em style='color:red'>", "g");
  if (str) {
    str = str.replace(htmlGPss, " ");
  }
  let htmlGPst = new RegExp("<font color='red'>", "g");
  if (str) {
    str = str.replace(htmlGPst, " ");
  }
  let htmlGPst2 = new RegExp("</font>", "g");
  if (str) {
    str = str.replace(htmlGPst2, " ");
  }
  let htmlGPssem = new RegExp("</em>", "g");
  if (str) {
    str = str.replace(htmlGPssem, "");
  }
  let htmlGPsss = new RegExp("&nbsp;", "g");
  if (str) {
    str = str.replace(htmlGPsss, "");
  }
  let htmlGPssss = new RegExp("<p>", "g");
  if (str) {
    str = str.replace(htmlGPssss, "");
  }
  let htmlGPsssss = new RegExp("</p>", "g");
  if (str) {
    str = str.replace(htmlGPsssss, "");
  }
  if (str) {
    str = str.replace(/<span style="color:red">/g, "");
    str = str.replace(/<\/span>/g, "");
  }
  // if (str) {
  //   str = str.replace(/[<em style="color:red"></em>/&nbsp;/<p>/</p>]/g, "");
  // }
  var textar = document.createElement("textarea");
  textar.value = str;
  document.body.appendChild(textar);
  textar.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  textar.remove();
  Message.success(title ? `${title}成功！` : "复制成功！");
};
// // goDetail 获取路由里的所有path
const getRouerPathList = () => {
  let obj = {};
  // 这里有我为你留下的坑
  router.options.routes[9].children.forEach((item) => {
    obj[item.name] = item;
  });
  return { ...obj };
};
// // 将获取的pathlist 导出去 通过这种方式导出 能够增加性能  避免重复访问 router 树
export const RouerPathList = { ...getRouerPathList() };
// 全选按钮方法
export function selectAll(val, myObj) {
  myObj.checkedform = val ? myObj.checkedData : []; // checkedform:选中的数组  checkedData:全选列表的基础值
  myObj.isIndeterminate = false; // isIndeterminateOne：半选
}
// 多选按钮方法
export function selections(length, myObj, backFn) {
  myObj.checkAll = length === myObj.checkedData.length; //  当前已选数组的长度 等于数据数组的长度 就为全选
  myObj.isIndeterminate = length > 0 && length < myObj.checkedData.length; // 当前数组大于0 并且 小于数据数据就是半选
  backFn && backFn(myObj);
}
//处理请求函数
export async function handleFn(fn, backFn) {
  let Res = await fn;
  if (Res) {
    const { code, data } = Res?.data;
    if (code == 200) {
      backFn && backFn(data);
      return { code, data };
    } else {
      return true;
    }
  } else {
    return true;
  }
}
// 处理请求而来的Res 返回 true || fasle
export const ifRes = (Res) => !Res || !Res.data || Res.data.code !== 200;
// 处理res 只返回第三层的数据  不封装也可以  主要是排版后 太丑了
export const getResData = (Res) => {
  if (!Res) return;
  if (!Res.data.data) return;
  const {
    data: { code, data },
  } = Res;
  return { code, data };
};
export const ifText = (text, desc) => (text ? text : desc ? desc : "无");
// 查看原文
export const lookYuanWen = (web) => {
  web.search("http") !== -1 ? window.open(web) : window.open("http://" + web);
};
// 批量处理对象的 trim()
export const setTrims = (obj) => {
  // 批量处理文字的 两边非空
  Object.keys(obj).forEach((key) => {
    obj[key] = obj[key].trim();
  });
  return obj;
};
// 处理回显多选框数据
export const backMyInfo = (my, data) => {
  // 把字符串转成数组 || 匹配已选数据
  data.split(",").forEach((v) => {
    my.checkedData.forEach((i) => {
      if (Number(v) == Number(i.value)) my.checkedform.push(i);
    });
  });
  //   检测已选数据和全部数据的长度 从而判断是否为全选
  selections(my.checkedform.length, my);
};
// 批量处理重置
export const setReset = (obj, _that) => {
  Object.keys(obj).forEach((key) => {
    _that[key] = _that.$options.data()[key];
  });
};

//发布内容,有害信息,错误表述列表跳  台账详情
export const goTaiZhang = (query = {}) => {
  window.open(
    router.resolve({
      path: "/accountDetail",
      query: {
        info: encodeURIComponent(JSON.stringify(query)),
      },
    }).href,
    "_blank"
  );
};
// 路由跳转
// 跳转之后的解码示例:let params = JSON.parse(decodeURIComponent(this.$route.query.info));
export const routeResolve = (path, query = {}, way = "_blank") => {
  window.open(
    router.resolve({
      path,
      query: {
        info: encodeURIComponent(JSON.stringify(query)),
      },
    }).href,
    way
  );
};
// 文字限制
export const maxlength = (length = 100) => length;
export const minlength = (length = 3) => length;
// 输入框最大文字
export const inputMaxLength = (length = 7) => length;
// 导出
export const exportFN = async (
  fn,
  obj,
  refs,
  ifBold,
  BoldName = "数据导出.xlsx",
  type = ""
) => {
  if (!type) {
    refs.exportDialog.exportVisible = true;
    refs.exportDialog.downloadProgress();
    const Res = await fn(obj);
    const blob = ifBold ? Res.data : new Blob([Res.data]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = ifBold ? BoldName : `${Res.data.data.name}.xlsx`;
    a.href = ifBold ? url : Res.data.data.url;
    a.click();
    URL.revokeObjectURL(url);
    if (refs.exportDialog.timer) clearTimeout(refs.exportDialog.timer);
    refs.exportDialog.wite = false;
    refs.exportDialog.percentage = 100;
  } else {
    const Res = await fn(obj);
    console.log(Res);
    Message.success("正在加载数据,请稍后在下载中心查看");
  }
};
// 随机数
export const sjNumFn = (max, min) =>
  Math.floor(Math.random() * (max - min)) + min;

// 上传文件失败
export const handleAvatarError = () => {
  Message({ message: "上传失败", type: "error" });
};
// 上传前文件判断
export const beforeAvatarUpload = (file, img) => {
  let isTYPE;
  if (img) {
    // 截图
    isTYPE = file.type === "image/jpeg" || file.type === "image/png";
  } else {
    // 所有类型
    isTYPE =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
  }
  if (!isTYPE) Message.error("上传附件格式错误!");
  return isTYPE;
};
// 处理列表图片 让列表图片不显示
export const listImgNo = (list) => {
  list.forEach((v) => {
    if (v.content)
      v.content = v.content.replaceAll("<img ", "<img style='display:none' ");
  });
  return list;
};
//  根据平台返回哪些字段需要影藏活显示
export const listConfigPlatformShow = (platformType, ConfigType) => {
  //  ConfigType  发文数:1 粉丝数:2 关注数:3
  //  platformType  网站:1 微信:3  大风:11  百家:6  大鱼:7  搜狐号:9
  const obj = {
    1: [1],
    3: [1, 2, 3],
    11: [1, 3],
    9: [1],
  };
  const narr = obj[platformType];
  let nb;
  if (narr) nb = narr.filter((v) => v == ConfigType);
  // console.log(platformType, ConfigType);
  // console.log(nb);
  return nb && nb.length > 0 ? false : true;
};

export const snapshot = (Time) => {
  return {
    snapshot: Time.slice(0, 7).replace("-", ""),
  };
  // const time = new Date()
  // const nian = time.getFullYear()
  // let  yue = time.getMonth() +1
  // console.log(time.getDate())
  // console.log(new Date(nian, yue, 0).getDate())
  // yue = yue < 10 ? '0'+yue:yue
  // return nian +''+yue
};
export const getBiaojiColor_fn = (disposalStatusName) => {
  if (disposalStatusName == "已处置") {
    return "#EA3342";
  } else if (disposalStatusName == "已删除") {
    return "#cbced6";
  } else if (disposalStatusName == "已修改") {
    return "#02BC7C";
  } else if (disposalStatusName == "未处置") {
    return "#EA3342";
  }
};
export let isPanDuanFN = (PanDuanObj) => {
  let back = true;
  for (let myObj of PanDuanObj) {
    if (!myObj?.item) {
      Message.warning(myObj?.tishi || "不能为空");
      back = false;
      break;
    }
  }
  return back;
};
