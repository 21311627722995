<template>
  <div>
    <el-dialog
      title="下载提示"
      :visible.sync="exportVisible"
      width="40%"
      class="dialog-box"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :show-close="false"
    >
      <div class="dialog-body">
        <span class="download-Wait" v-if="wite">正在下载...</span>
        <span class="download-Wait" v-else>下载完成！</span>
        <el-progress
          :stroke-width="22"
          :text-inside="true"
          :percentage="percentage"
        ></el-progress>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="downloadYes"
          size="medium"
          type="primary"
          class="search_z"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportVisible: false, //显隐
      wite: true, //下载状态
      percentage: 0, //进度条数值
      timer: null,
    };
  },
  mounted() {},
  methods: {
    // 下载确定按钮
    downloadYes() {
      this.exportVisible = false;
      this.percentage = 0;
      this.wite = true;
    },
    //下载进度模拟
    downloadProgress() {
      this.timer = setTimeout(() => {
        if (this.percentage + 5 >= 90) {
          this.percentage = 90;
        } else {
          this.percentage += 5;
        }
        this.downloadProgress();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-body {
  // height: 100px;
  padding: 32px 48px;
  .download-Wait {
    display: inline-block;
    margin-bottom: 15px;
  }
}

.el-button {
  min-width: 75px;
}
</style>