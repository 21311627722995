<template>
  <div class="deleteDialog">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
    >
      <!-- <div v-if="dataObj.keys" class="deleteDiv">
        <p>是否确认删除当前观点?</p>
        <p>删除任务执行中请勿进行数据筛选，否则数据会有误差。</p>
      </div>
      <div v-else-if="dataObj.logout" class="deleteDivTwo">
        <span>请确认是否退出登录?</span>
      </div> -->
      <div v-if="type == '7'" class="deleteDivTwo">
        <span>此操作将永久删除所选数据, 是否继续?</span>
      </div>
      <div v-else-if="dataObj.logout" class="deleteDivTwo">
        <span>请确认是否退出登录？</span>
      </div>
      <div v-else class="deleteDivTwo">
        <span>此操作将永久删除{{ dataObj.message }}, 是否继续?</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureClick">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dataObj: {},
      type: "",
    };
  },
  props: {},
  methods: {
    handleClose(done) {
      done();
    },
    openDialog(data, type = 2) {
      this.dialogVisible = true;
      this.dataObj = data;
      this.type = type;
    },
    sureClick() {
      const { type } = this;
      // 操作类别-type: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6 取消收藏 7 批量删除
      this.$emit("deleteFn", { type, item: this.dataObj });
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.deleteDiv {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 40px;
  p {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
}
.deleteDivTwo {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  span {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>